import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { toast } from "react-toastify";
import { axiosApi } from "../../helpers/axios";

const initialStates = {
  loading: false,
  error: "",
  createTokenData: {},
  allIntroducerCodeData: {},
  getIntroducerCodeData: {},
  feedbackListData: {},
};

// ** create Token Api
export const createTokenApi = createAsyncThunk(
  "createTokenApi",
  async ({ data, toggleModal, modal }, { rejectWithValue }) => {
    try {
      const response = await axiosApi.post(`/code/code-generate`, data);
      if (response) {
        toast.success(response?.data?.msg);
        toggleModal(!modal);
      }
      return response.data.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);
// ** all introducer code Api
export const allIntroducerCodeAPI = createAsyncThunk(
  "allIntroducerCodeAPI",
  async ({ page, limit, search, introduceId }, { rejectWithValue }) => {
    try {
      const response = await axiosApi.get(
        `/code/get-allintroducer-code?page=${page}&limit=${limit}&search=${search}&introduceId=${introduceId}`,
        null
      );
      return response?.data?.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);
// ** feedback list Api
export const feedbackListAPI = createAsyncThunk(
  "feedbackListAPI",
  async ({ page, limit, search }, { rejectWithValue }) => {
    try {
      const response = await axiosApi.get(
        `/feedback/get-all-feedback?page=${page}&limit=${limit}&search=${search}`,
        null
      );
      return response?.data?.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);
// ** get introducer code Api
export const getIntroducerCodeAPI = createAsyncThunk(
  "getIntroducerCodeAPI",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await axiosApi.get(
        `/code/get-introducer-code/${id}`,
        null
      );
      return response?.data?.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

const codeHistorySlice = createSlice({
  name: "codeHistory",
  initialState: initialStates,
  extraReducers: {
    [createTokenApi.pending]: (state) => {
      state.loading = true;
    },
    [createTokenApi.fulfilled]: (state, action) => {
      state.loading = false;
      state.createTokenData = action.payload;
    },
    [createTokenApi.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [allIntroducerCodeAPI.pending]: (state) => {
      state.loading = true;
    },
    [allIntroducerCodeAPI.fulfilled]: (state, action) => {
      state.loading = false;
      state.allIntroducerCodeData = action.payload;
    },
    [allIntroducerCodeAPI.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [feedbackListAPI.pending]: (state) => {
      state.loading = true;
    },
    [feedbackListAPI.fulfilled]: (state, action) => {
      state.loading = false;
      state.feedbackListData = action.payload;
    },
    [feedbackListAPI.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getIntroducerCodeAPI.pending]: (state) => {
      state.loading = true;
    },
    [getIntroducerCodeAPI.fulfilled]: (state, action) => {
      state.loading = false;
      state.getIntroducerCodeData = action.payload;
    },
    [getIntroducerCodeAPI.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

const { reducer } = codeHistorySlice;

export default reducer;
